export default function InPageVideo({ video }) {
  return (
    <div className="row position-relative">
      {/* <video
            src={homeVideo}
            autoPlay
            loop
            muted
            playsInline
            className="p-0 homeVidSize"
          /> */}

      <div
        className="p-0"
        dangerouslySetInnerHTML={{
          __html: `<video class="homeVidSize align-bottom" autoplay loop muted playsinline preload="auto">
      <source src=${video} type="video/mp4" />
      Your browser does not support the video.
</video>`,
        }}
      />
    </div>
  );
}
